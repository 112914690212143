import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import {motion} from 'framer-motion'

const headerAnimation = {
  visible: { opacity: 1},
  hidden: { opacity: 0},
}

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#fff`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        borderBottom: '2px solid black',
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <motion.h1 style={{ margin: 0 }} initial='hidden' animate='visible' variants={headerAnimation}>
        <Link
          to="/"
          style={{
            textDecoration: `none`,
            color: '#3486eb'
          }}
        >
          {siteTitle}
        </Link>
      </motion.h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
